/**
 * @description: Exception related enumeration
 */
export enum ExceptionEnum {
  // sitting title
  TITLE = 402,
  // jump path
  PATH = 100,
  // back
  BACK = 101,
  // close
  CLOSE = 102,

  // login is error
  LOGIN_ERROR = 401,
  CODE_ERROR = 400,

  // page not access
  PAGE_NOT_ACCESS = 403,

  // page not found
  PAGE_NOT_FOUND = 404,

  // error
  ERROR = 500,

  // net work error
  NET_WORK_ERROR = 10000,

  // No data on the page. In fact, it is not an exception page
  PAGE_NOT_DATA = 10100,
}

export enum ErrorTypeEnum {
  VUE = 'vue',
  SCRIPT = 'script',
  RESOURCE = 'resource',
  AJAX = 'ajax',
  PROMISE = 'promise',
}
