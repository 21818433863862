export enum PageEnum {
  // basic login path
  BASE_LOGIN = '/login',
  // basic home path
  BASE_HOME = '/Dashboard/Dashboard',
  // error page path
  ERROR_PAGE = '/exception',
}

export enum FeedPageEnum {
  FEED_TT,
  FEED_FB,
  FEED_GG,
}

export enum IndexEnum {
  ONE,
  TWO,
  THREE,
  FOURTH,
  FIVE,
}

export enum FeedEnum {
  TIKTOK = 'Tiktok',
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
}
