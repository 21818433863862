export enum PageEnum {
  // basic login path
  BASE_LOGIN = '/login',
  // basic home path
  BASE_HOME = '/workbench/workbench',
  // BASE_HOME = '/Dashboard/Dashboard',
  // error page path
  ERROR_PAGE = '/exception',
  PRODUCT_TAGS = '/productTags',
}

export enum FeedPageEnum {
  FEED_TT,
  FEED_FB,
  FEED_GG,
}

export enum IndexEnum {
  ONE,
  TWO,
  THREE,
  FOURTH,
  FIVE,
}

export enum FeedEnum {
  TIKTOK = 'Tiktok',
  FACEBOOK = 'Facebook',
  GOOGLE = 'Google',
}

export const bestLineOptions = [
  {
    value: '-eo.bloomeverybody.work',
    label: '线路一',
  },
  {
    value: '.bloomeverybody.work',
    label: '线路二',
  },
]
